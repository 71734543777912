import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Location} from '../../../models/Location.model';
import {PageService} from '../../../modules/pages/pages/page.service';
import Swal from 'sweetalert2';
import {User} from '../../../models/Use.model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { PredictionsService } from 'src/app/services/predictions.service';


declare var $;

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  private SideNaveApi = 'api/dashboard/sidenav';
  private names = [];
  message: string;
  CurrentUser = new User();
  constructor(private router: Router,private http: HttpClient, private pageServise: PageService,public translateService:TranslateService,private key:PredictionsService) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('lang') || 'en');
  }
  private async checkkey(){
    this.key.getkeys().subscribe(async(data)=>{
      const resSTR = JSON.stringify(data);
      const resJSON = JSON.parse(resSTR);
      if(resJSON.status==="err"){
        alert("Add license to use our product!");
        await this.router.navigate(['/dashboard/Predictions']);
        return;
      }
      //this.ItemsArray = res;
      //console.log("fssst",this.ItemsArray);
    })
  }

  ngOnInit() {
    this.checkkey();
    this.CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.pageServise.currentMessage.subscribe(message => this.message = message);
    console.log("notifme",this.message);
    this.load_data();
    $(document).ready(() => {
      $('.sidebar-menu').tree();
    });
  }
  async load_data() {
    const options = {
      params: new HttpParams().append('token', localStorage.getItem('token'))
    };
    await this.http.get(this.SideNaveApi, options).subscribe(data => {
      const resSTR = JSON.stringify(data);
      const resJSON = JSON.parse(resSTR);
      if (resJSON.status === 'ok') {
        resJSON.response.forEach((item) => {
          this.names.push(item);
        });
      } else if (resJSON.status === 'err') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: resJSON.message,
        });
      }
    }, error => {
      console.log(error.toString());
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
      console.log(error.toString());
    });
    console.log(this.names);
    if (this.names.length > 0 ) {
      this.newMessge(this.names[0]._id);
    } else {
      this.newMessge('none here');
    }
      }
      newMessge(name) {
          this.pageServise.changeMessage(name);
      }
      onClick(item) {
          console.log(item);
          this.newMessge(item._id);
      }

}
