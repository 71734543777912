import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class PredictionsService {
  private geturl1 = "/api/dashboard/getkey";
  constructor(private http : HttpClient) {   }
  private options = {
    params: new HttpParams().append('token', localStorage.getItem('token'))
  };
  getkeys(){
    console.log("getkeys")
    
    return this.http.get(this.geturl1,this.options);
  }
}
