import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './modules/layout/layout.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AuthGuardGuard } from './modules/auth-guard.guard';
import { AuthServiceService } from './modules/auth/auth-service.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TestappComponent } from './testapp/testapp.component';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/shared_/material.module';

// const ngxUiLoaderConfig: NgxUiLoaderConfig = {
//   text: "Loading...",
//   textColor: "#FFFFFF",
//   textPosition: "center-center",
//   pbColor: "red",
//   bgsColor: "red",
//   fgsColor: "red",
//   fgsType: SPINNER.ballSpinClockwise,
//   fgsSize: 100,
//   pbDirection: PB_DIRECTION.leftToRight,
//   pbThickness: 5
// }

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('84773409290-i5q9eiinqq7vek5rnesga5o4uus77ht3.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('582042822664841')
  }
]);
export function provideConfig() {
  return config;
}
@NgModule({
  declarations: [
    AppComponent,
    TestappComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    ChartsModule,
    SocialLoginModule,
    AngularDateTimePickerModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,

  ],

  providers: [
    AuthServiceService, AuthGuardGuard,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    HttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
